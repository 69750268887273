<template>
  <Teleport v-if="props.show" to="body">
    <div
      class="fixed top-0 right-0 bottom-0 left-0 bg-black/20 z-10"
      @click="$emit('onClick')"
    ></div>
  </Teleport>
</template>
<script setup>
const props = defineProps({
  show: Boolean
})
</script>
